import { useAuthStore } from "~/stores/AuthStore";
export default function useCheckRequestStatusCode() {
  async function checkStatusCode(status_code) {
    const authStore = useAuthStore();

    if (status_code === 401) {
      const token = useCookie("token");
      token.value = null;
      delete authStore.auth.headers.Authorization;
      authStore.auth.user = {};
    }    
    if (status_code === 503) {
      navigateTo('/maintenance')
    }

  }
  return { checkStatusCode };
}
