/* eslint-disable no-undef */
import { defineStore } from "pinia";
import { useRuntimeConfig } from "#app";
import { useCartStore } from "./CartStore";
import { useSettingsStore } from "./SettingsStore";
export const useAuthStore = defineStore("auth", {
  state: () => ({
    loading: false,
    serverErrors: [],
    hasGARInformation: false,
    informMeWhenMaterialBeAvailable: false,

    signUp: {
      id: "",
      countries: null,
    },
    signInData: {
      id: "",
    },
    auth: {
      user: null,
      isLoggedIn: false,
      headers: {
        Authorization: `${useCookie("token") ? `Bearer ${useCookie("token")}` : null}`,
        accept: "application/json",
      },
    },
  }),

  actions: {
    async register(formData) {
      this.loading = true;
      const { checkStatusCode } = useCheckRequestStatusCode();
      const { data: response, error } = await useFetch(
        `${useRuntimeConfig().public.baseUrl}register`,
        {
          method: "POST",
          body: formData,
        },
      );
      this.loading = false;
      setTimeout(() => {
        this.responseData = null;
      }, 3000);

      if (error.value) {
        this.responseData = error.value ? error.value.data : "حدث خطأ ما";
        checkStatusCode(error.value.data ? error.value.data.status_code : 401);
        return error.value.data;
      } else {
        this.responseData = response.value ? response.value : "حدث خطأ ما";
        return response.value;
      }
    },
    async login(payload) {
      const { checkStatusCode } = useCheckRequestStatusCode();
      const formData = new FormData();
      this.loading = true;

      for (const key in payload) {
        if (payload[key]) {
          if (key === "cartItems") {
            payload[key].forEach((product, index) => {
              formData.append(`products[${index}][product_id]`, product.id);
              formData.append(`products[${index}][product_type]`, product.type);

              if (product.ref) {
                formData.append(`products[${index}][ref]`, product.ref);
              }
              if (product.promo_code) {
                formData.append(
                  `products[${index}][promo_code]`,
                  product.promo_code,
                );
              }
            });
          } else {
            formData.append(key, payload[key]);
          }
        }
      }
      const { data: response, error } = await useFetch(
        `${useRuntimeConfig().public.baseUrl}login`,
        {
          method: "POST",
          body: formData,
        },
      );
      this.loading = false;
      setTimeout(() => {
        this.responseData = null;
      }, 3000);

      if (error.value) {
        this.responseData = error.value ? error.value.data : "حدث خطأ ما";

        checkStatusCode(error.value.data ? error.value.data.status_code : 401);
        checkStatusCode(error.value.data ? error.value.data.status_code : 401);
        this.serverErrors = error.value.data ? error.value.data.errors : [];
        return error.value.data;
      } else if (response.value.status_code === 301) {
        this.statuses = response.value;
        return response.value;
      } else if (response.value) {
        if (response.value.data) {
          const settingsStore = useSettingsStore();
          const cartStore = useCartStore();
          const route = useRoute();
          settingsStore.showProfile();
          cartStore.getCartDetails();
          this.responseData = response.value ? response.value : "حدث خطأ ما";
          const token = useCookie("token", {
            expires: new Date(Date.now() + 7 * 24 * 60 * 60 * 1000), // 7 days from now
          });

          token.value = response.value.data.token || "";

          // const { setValue } = useLocalStorage();
          // setValue("token", response.value.data.token); // Store Token in LocalStorage
          this.setHeaders(response.value.data.token);
          await this.getProfile();
          // if (route.path === "/cart") {
          //   navigateTo("/cart/checkout");
          // }
        }

        return response.value;
      }
    },
    async showProfile() {
      this.loading = true;
      const { checkStatusCode } = useCheckRequestStatusCode();
      try {
        const { data, error } = await useFetch(
          `${useRuntimeConfig().public.baseUrl}profile`,
          {
            method: "GET",
            headers: this.auth.headers,
          },
        );
        this.loading = false;
        if (error.value) {
          checkStatusCode(
            error.value.data ? error.value.data.status_code : 401,
          );
          return error.value.data;
        }
        this.profile = data.value ? data.value.data : {};

        this.hasGARInformation = data.value.user.gar_information !== "unknown";
        return data.value;
      } catch (e) {
        this.loading = false;
        return e.response ? e.response.data : {};
      }
    },
    async getProfile() {
      this.loading = true;
      const { checkStatusCode } = useCheckRequestStatusCode();
      if (this.auth.headers.Authorization) {
        const route = useRoute();

        this.loading = true;
        const { data: response, error } = await useFetch(
          `${useRuntimeConfig().public.baseUrl}profile`,
          {
            method: "GET",
            headers: this.auth.headers,
          },
        );
        this.loading = false;
        if (error.value) {
          checkStatusCode(
            error.value.data ? error.value.data.status_code : 401,
          );
          return error.value ? error.value.data : {};
        } else {
          this.auth.isLoggedIn = true;
          this.auth.user = response.value.data.user;
          this.hasGARInformation =
            response.value.data.gar_information !== "unknown";

          return response.value ? response.value.data : {};
        }
      }
    },
    async resendActivationEmail(payload) {
      this.loading = true;
      const { checkStatusCode } = useCheckRequestStatusCode();
      const { data: response, error } = await useFetch(
        `${useRuntimeConfig().public.baseUrl}resend-activation-email`,
        {
          method: "POST",
          headers: this.auth.headers,
          body: payload,
        },
      );
      this.loading = false;
      if (error.value) {
        checkStatusCode(error.value.data ? error.value.data.status_code : 401);
        return error.value.data;
      } else {
        return response.value;
      }
    },
    async changeEmail(payload) {
      this.loading = true;
      const { checkStatusCode } = useCheckRequestStatusCode();
      const { data: response, error } = await useFetch(
        `${useRuntimeConfig().public.baseUrl}change-email`,
        {
          method: "POST",
          headers: this.auth.headers,
          body: payload,
        },
      );
      this.loading = false;
      if (error.value) {
        checkStatusCode(error.value.data ? error.value.data.status_code : 401);
        return error.value.data;
      } else {
        return response.value.data;
      }
    },
    async changePassword(payload) {
      this.loading = true;
      const { checkStatusCode } = useCheckRequestStatusCode();
      const { data: response, error } = await useFetch(
        `${useRuntimeConfig().public.baseUrl}password/change`,
        {
          method: "POST",
          headers: this.auth.headers,
          body: payload,
        },
      );
      this.loading = false;
      if (error.value) {
        checkStatusCode(error.value.data ? error.value.data.status_code : 401);
        return error.value.data;
      } else {
        return response.value.data;
      }
    },
    async forgotPassword(payload) {
      this.loading = true;
      const { checkStatusCode } = useCheckRequestStatusCode();
      const { data: response, error } = await useFetch(
        `${useRuntimeConfig().public.baseUrl}forgot-password`,
        {
          method: "POST",
          headers: this.auth.headers,
          body: payload,
        },
      );
      this.loading = false;
      if (error.value) {
        checkStatusCode(error.value.data ? error.value.data.status_code : 401);
        return error.value.data;
      } else {
        return response.value;
      }
    },
    async resetPassword(payload) {
      this.loading = true;
      const { checkStatusCode } = useCheckRequestStatusCode();
      const { data: response, error } = await useFetch(
        `${useRuntimeConfig().public.baseUrl}reset-password/${payload.token}`,
        {
          method: "POST",
          headers: this.auth.headers,
          body: payload.formData,
        },
      );
      this.loading = false;
      if (error.value) {
        checkStatusCode(error.value.data ? error.value.data.status_code : 401);
        return error.value.data;
      } else {
        return response.value;
      }
    },
    async activateAccount(payload) {
      this.loading = true;
      const { checkStatusCode } = useCheckRequestStatusCode();
      const { data: response, error } = await useFetch(
        `${useRuntimeConfig().public.baseUrl}activate-account?token=${payload.token}&email=${payload.email}`,
        {
          method: "POST",
          headers: this.auth.headers,
        },
      );
      this.loading = false;
      if (error.value) {
        checkStatusCode(error.value.data ? error.value.data.status_code : 401);

        return error.value.data;
      } else {
        return response.value;
      }
    },
    async updateProfile(payload) {
      this.loading = true;
      const { checkStatusCode } = useCheckRequestStatusCode();
      const { data: response, error } = await useFetch(
        `${useRuntimeConfig().public.baseUrl}profile`,
        {
          method: "POST",
          headers: this.auth.headers,
          body: payload,
        },
      );
      this.loading = false;
      if (error.value) {
        checkStatusCode(error.value.data ? error.value.data.status_code : 401);
        return error.value.data;
      } else {
        return response.value;
      }
    },
    async logout(payload) {
      const { checkStatusCode } = useCheckRequestStatusCode();
      const cartStore = useCartStore();
      const { data: response, error } = await useFetch(
        `${useRuntimeConfig().public.baseUrl}logout`,
        {
          method: "POST",
          headers: this.auth.headers,
          body: payload.formData,
          // params: { device_id: payload.device_id },
        },
      );
      if (error.value) {
        checkStatusCode(error.value.data ? error.value.data.status_code : 401);
        return error.value.data;
      } else {
        this.auth = {
          user: null,
          isLoggedIn: false,
          headers: {
            accept: "application/json",
          },
        };
        this.informMeWhenMaterialBeAvailable = false,
        cartStore.cartList = [];
        return response.value;
      }
    },
    setHeaders(token) {
      this.auth.headers.Authorization = `Bearer ${token}`;
    },
  },
});
