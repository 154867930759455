<template>
  <div>
    <v-dialog
      v-model="showLoginDialog"
      :max-width="selectedForm === 'login' ? '500' : '550'"
      persistent
    >
      <Alert
        :alert-visible="showAlert"
        :alert-data="alertData"
        @close="showAlert = false"
      />
      <changeEmailForm
        v-if="showChangeEmailForm === true"
        @showAlert="onShowAlert"
        @closeModal="closeChangeEmailDialog"
      />
      <v-card v-else class="cart-alert rtl">
        <v-btn
          elevation="0"
          icon
          dark
          @click="showLoginDialog = false"
          class="close-dialog-icon"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <br />
        <v-row>
          <v-col cols="12">
            <p>الرجاء تسجيل الدخول أو إنشاء حساب جديد</p>
          </v-col>
          <v-col cols="12" sm="6">
            <v-btn
              :color="selectedForm === 'login' ? '#4527a0' : '#f4f4f4'"
              block
              elevation="0"
              size="large"
              rounded="xl"
              @click="selectedForm = 'login'"
              >تسجيل الدخول</v-btn
            >
          </v-col>
          <v-col cols="12" sm="6">
            <v-btn
              :color="selectedForm === 'signup' ? '#4527a0' : '#f4f4f4'"
              block
              elevation="0"
              size="large"
              rounded="xl"
              @click="selectedForm = 'signup'"
            >
              إنشاء حساب جديد
            </v-btn>
          </v-col>
        </v-row>
        <v-card-title>
          <h2 v-if="selectedForm === 'login'">تسجيل دخول</h2>
          <h2 v-else>إنشاء حساب جديد</h2>
        </v-card-title>

        <!-- <v-card-text class="visitor-login-modal"> -->
        <v-form
          v-if="selectedForm === 'login'"
          ref="formRef"
          class="modal-form"
          lazy-validation
        >
          <v-text-field
            v-model="form.email"
            required
            class="input-email mb-1 font-weight-bold"
            placeholder="البريد الإلكتروني"
            type="email"
            :rules="emailRules"
            @keydown.enter="login"
            validate-on-blur
            @blur="() => ($refs.formRef ? $refs.formRef.resetValidation() : '')"
          />
          <v-text-field
            v-model="form.password"
            class="input-email font-weight-bold"
            placeholder="كلمة المرور"
            :type="showPassword ? 'text' : 'password'"
            :rules="passwordRules"
            validate-on-blur
            @blur="() => ($refs.formRef ? $refs.formRef.resetValidation() : '')"
            ><template #append-inner>
              <!-- <v-btn color="#4527a0" icon> -->
              <i
                v-if="showPassword"
                @click="showPassword = !showPassword"
                class="fa fa-eye cursor-pointer eye-icon"
              ></i>
              <i
                v-else
                @click="showPassword = !showPassword"
                class="fa fa-eye-slash cursor-pointer eye-icon"
              ></i>
              <!-- </v-btn> -->
            </template>
          </v-text-field>

          <div v-if="isError" class="v-text-field__details">
            <div class="v-messages theme--light error--text" role="alert">
              <div class="v-messages__wrapper">
                <div
                  class="v-messages__message message-transition-enter-to text-error text-right"
                >
                  {{ errorMessage }}
                </div>
              </div>
            </div>
          </div>
          <div class="forget-password">
            <span @click="forgetPassword">نسيت كلمة المرور</span>
            <div class="d-flex align-center">
              <v-checkbox
                v-model="form.remember_me"
                color="#464688"
                hide-details
              >
              </v-checkbox>
              <span class="font-weight-bold text-black"> تذكرني </span>
            </div>
          </div>
          <v-btn
            :loading="authStore.loading || homeStore.loading"
            class="login-btn"
            height="56"
            :class="notActive ? 'not-active' : ''"
            @click="login"
          >
            <span> تسجيل الدخول </span>
          </v-btn>
          <v-btn
            v-if="notActive"
            :loading="authStore.loading"
            height="56"
            class="login-btn"
            @click="activateEmail"
          >
            <span>إعادة ارسال رسالة التفعيل</span>
          </v-btn>
          <div v-if="notActive" class="activate-section">
            <span class="unactive-account-hint">
              حسابك غير مفعل ، تم إرسال رابط التفعيل عبر البريد الإلكتروني ،
              يرجى التحقق واتباع التعليمات.
            </span>
            <span class="card-line" />
            <span class="subtitle-text">
              اذا لم تصلك رسالة التأكيد فضلاً التحقق من البريد الإلكتروني الغير
              هام / المهمل او يمكنك تغيير البريد الإلكتروني
            </span>
            <v-btn
              :loading="authStore.loading"
              class="login-btn"
              height="56"
              @click="openChangeEmailDialog"
            >
              <span>تغيير البريد الإلكتروني</span>
            </v-btn>
          </div>
        </v-form>
        <v-form v-else ref="formRef" class="modal-form" lazy-validation>
          <v-row>
            <v-col cols="12" class="pb-0">
              <p class="text-right font-weight-normal text-sm">
                الرجاء تعبئة البيانات التالية لإنشاء حساب جديد
              </p>
            </v-col>
            <v-col cols="12">
              <v-text-field
                v-model="form.full_name"
                class="input-email font-weight-bold"
                placeholder="الاسم الكامل"
                type="text"
                :rules="requiredRules"
                validate-on-blur
                @blur="
                  () => ($refs.formRef ? $refs.formRef.resetValidation() : '')
                "
              />
            </v-col>
          </v-row>

          <v-text-field
            v-model="form.email"
            class="input-email font-weight-bold"
            placeholder="البريد الإلكتروني"
            type="email"
            :rules="emailRules"
            validate-on-blur
            @blur="() => ($refs.formRef ? $refs.formRef.resetValidation() : '')"
          />
          <v-text-field
            v-model="form.password"
            class="input-email font-weight-bold"
            placeholder="كلمة المرور"
            :type="showPassword ? 'text' : 'password'"
            :rules="passwordRules"
            validate-on-blur
            @blur="() => ($refs.formRef ? $refs.formRef.resetValidation() : '')"
            ><template #append-inner>
              <!-- <v-btn color="#4527a0" icon> -->
              <i
                v-if="showPassword"
                @click="showPassword = !showPassword"
                class="fa fa-eye cursor-pointer eye-icon"
              ></i>
              <i
                v-else
                @click="showPassword = !showPassword"
                class="fa fa-eye-slash cursor-pointer eye-icon"
              ></i>
              <!-- </v-btn> -->
            </template>
          </v-text-field>
          <v-autocomplete
            v-model="form.country_id"
            :items="countries"
            item-title="name"
            item-value="id"
            placeholder="الدولة"
            class="country font-weight-bold visitor-country"
            dense
            filled
            outlined
            solo
            :rules="requiredRules"
            validate-on-blur
            @blur="() => ($refs.formRef ? $refs.formRef.resetValidation() : '')"
          />

          <div class="phone-number">
           
            <v-text-field
              :class="form.phone === '' ? 'righ-hint' : ''"
              v-model="form.phone"
              placeholder="رقم الجوال"
              type="number"
              hide-spin-buttons
              :min-length="7"
              :rules="phoneNumber"
              validate-on-blur
              @blur="() => ($refs.formRef ? $refs.formRef.resetValidation() : '')"
              />
              <span class="phone_code">
              {{
                selectedCountry.phone_code ? selectedCountry.phone_code : "+967"
              }}
            </span>
          </div>

          <div>
            <div class="d-flex align-center active-subscription">
              <v-checkbox
                v-model="form.is_email_subscription"
                color="#464688"
                hide-details
              />
              <span class="font-weight-bold text-black">
                الاشتراك في النشرة البريدية
              </span>
            </div>
          </div>
          <div class="mt-3">
            <v-btn
              :loading="authStore.loading || homeStore.loading"
              class="login-btn mb-5 mt-0"
              height="56"
              @click="selectedForm.value === 'login' ? login() : signup()"
            >
              <span> إنشاء حساب </span>
            </v-btn>
          </div>

          <p class="text-right font-weight-normal text-sm mb-3">
            بإنشائك حساب جديد ، فأنت توافق على

            <v-btn
              to="/pages/terms-and-conditions-with-privacy-policy"
              target="_blank"
              class="px-0"
              variant="text"
              color="#464688"
            >
              <span class="font-weight-bold text-decoration-underline">
                الشروط والأحكام
              </span>
            </v-btn>
            الخاصة بنا
          </p>
        </v-form>

        <!-- <div class="or-divider d-flex align-center">
          <v-divider class="or-divider-line"></v-divider>
          <p class="or-divider-text text-sm">أو التسجيل من خلال</p>
          <v-divider class="or-divider-line"></v-divider>
        </div> -->

        <!-- <div class="or-divider d-flex align-center">
          <v-divider class="or-divider-line"></v-divider>
          <p class="or-divider-text text-sm">أو تسجيل الدخول من خلال</p>
          <v-divider class="or-divider-line"></v-divider>
        </div>

        <div class="d-flex justify-center align-center mt-3">
          <img
            v-for="(link, index) in socialMedia"
            :key="index"
            :src="`/images/auth/${link}.svg`"
            :alt="`${link} image`"
            class="mx-1"
          />
        </div> -->
        <!-- </v-card-text> -->
      </v-card>
    </v-dialog>
    <ForgetPassword
      :dialog-visible="showForgetDialog"
      @closeModal="isForgetDialogClosed"
    />
    <ActivationDialog
      :dialog-visible="showDialog"
      :dialog-data="dialogData"
      @closeModal="isDialogClosed"
    />
    <UpdatedTermsDialog
      :dialog-visible="showReadTermsDialog"
      @dialogClosed="onTermsDialogClosed"
      @acceptTerms="onAcceptTerms"
      :message="termsDialogContent"
    />
  </div>
</template>

<script setup>
import { ref, watch, reactive, toRefs } from "vue";
import { useRouter, useRoute } from "vue-router";

import UpdatedTermsDialog from "./UpdatedTermsDialog.vue";
import { useNuxtApp } from "#app";
import ActivationDialog from "~/components/auth/ActivationDialog.vue";
import Alert from "~/components/shared/Alert.vue";
import ForgetPassword from "~/components/auth/ForgetPassword.vue";
import ChangeEmailForm from "~/components/auth/changeEmailForm.vue";
import { useAuthStore } from "~/stores/AuthStore";
import { useHomeStore } from "~/stores/HomeStore";

const authStore = useAuthStore();
const homeStore = useHomeStore();
const { trackingPurchaseJourney } = useTrackingEvents();

const props = defineProps({
  dialogVisible: {
    type: Boolean,
    default: false,
  },
  openThisPath: {
    type: String,
    default: "",
  },
  setInCart: {
    type: Boolean,
    default: false,
  },
  isItFree: {
    type: Boolean,
    default: false,
  },
  informMeAboutTheMaterial: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits([
  "closeModal",
  "onSetInCart",
  "onSetInMySubscription",
]);

const router = useRouter();
const route = useRoute();

const { $fire, $config } = useNuxtApp();

const showPassword = ref(false);
const selectedForm = ref("login");
const termsDialogContent = ref("");
const isError = ref(false);
const errorMessage = ref("");
const showLoginDialog = ref(false);
const showReadTermsDialog = ref(false);
const showTermsDialog = ref(false);
const showForgetDialog = ref(false);
const showChangeEmailForm = ref(false);
const showDialog = ref(false);
const dialogData = ref({});
const showAlert = ref(false);
const alertData = ref({});
const showSignup = ref(false);
const showReadPolicyCheckBox = ref(false);
const hasReadPolicy = ref(false);
const loading = ref(false);
const formRef = ref({});

const form = reactive({
  email: null,
  password: null,
  country_id: "",
  remember_me: true,
  device_id: null,
  has_read_policy: true,
  fcm_token: null,
  full_name: null,
  country_id: null,
});
const socialMedia = reactive(["x", "facebook", "google", "apple"]);
const countries = computed(() => {
  return homeStore.countries ? homeStore.countries.countries : [];
});

const selectedCountry = computed(() => {
  let obj = {};
  if (countries.value) {
    countries.value.forEach((country) => {
      if (form.country_id === country.id) {
        obj = { ...country };
      }
    });
  }
  return obj;
});

const notActive = ref(false);

const phoneNumber = [
  (v) => !!v || "رقم الهاتف مطلوب",
  (v) => v.length >= 7 || "رقم الهاتف  يجب أن لا يقل عن 7 رقم",
  (v) => v.length <= 15 || "رقم الهاتف  يجب أن لا يزيد عن 15 رقم",
];

const requiredRules = [(v) => !!v || "هذا الحقل مطلوب"];

const emailRules = [
  (v) => !!v || "البريد الإلكتروني مطلوب",
  (v) =>
    /^(([^<>()[\]\\.,;:\s@']+(\.[^<>()\\[\]\\.,;:\s@']+)*)|('.+'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
      v,
    ) || "البريد الإلكتروني غير صحيح",
];

const passwordRules = [
  (v) => !!v || "كلمة المرور مطلوبة",
  (v) => v.length >= 2 || "طول كلمة المرور يجب أن لا يقل عن 2 أحرف",
];

const isIOSDevice = ref(false);

if (process.client) {
  onMounted(() => {
    authStore.loading = false;
    isIOSDevice.value =
      [
        "iPad Simulator",
        "iPhone Simulator",
        "iPod Simulator",
        "iPad",
        "iPhone",
        "iPod",
      ].includes(navigator.platform) ||
      (navigator.userAgent.includes("Mac") && "ontouchend" in document);
  });
}

watch(
  () => props.dialogVisible,
  (val) => {
    showLoginDialog.value = val;
    if (val === true) {
      showSignup.value = false;
      setNotificationToken();
      setDeviceId();
    }
  },
);

watch(
  () => countries.value,
  () => {
    countries.value.forEach((country) => {
      if (country.selected == true) {
        form.country_id = country.id;
      }
    });
  },
);

watch(showLoginDialog, (val) => {
  if (!val) {
    isError.value = false;
    emit("closeModal", { value: true, showSignup: showSignup.value });
    if (props.setInCart) {
      emit("onSetInCart", true);
    }
    if (props.isItFree) {
      emit("onSetInMySubscription", true);
    }
    resetForm();
  }
});

const resetForm = () => {
  form.email = "";
  form.password = "";
  form.remember_me = true;
  form.device_id = "";
  form.fcm_token = "";
  loading.value = false;
  notActive.value = false;
};

const activateEmail = async () => {
  const valid =
    formRef.value && formRef.value.errors
      ? (await formRef.value.validate()).valid
      : true;

  if (valid) {
    const formData = new FormData();
    formData.set("email", form.email);
    const resData = await authStore.resendActivationEmail(formData);
    if (resData.success) {
      dialogData.value = {
        type: "",
        msg: resData.message,
      };
      showLoginDialog.value = false;
      showDialog.value = true;
      notActive.value = false;
    } else {
      setAlertData(resData);
    }
  }
};

const openChangeEmailDialog = () => {
  showChangeEmailForm.value = true;
};

const onShowAlert = (data) => {
  setAlertData(data);
  if (data.success) {
    showChangeEmailForm.value = false;
    notActive.value = false;
    resetForm();
  }
};

const checkEmail = (value) => {
  if (value.includes("@")) {
    return /^(([^<>()[\]\\.,;:\s@']+(\.[^<>()\\[\]\\.,;:\s@']+)*)|('.+'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
      value,
    );
  } else {
    return value;
  }
};

const isForgetDialogClosed = (payload) => {
  if (payload.value) {
    showForgetDialog.value = false;
  }
};

const isSupported = () => {
  return (
    "Notification" in window &&
    "serviceWorker" in navigator &&
    "PushManager" in window
  );
};

const isDialogClosed = (payload) => {
  if (payload.value) {
    showDialog.value = false;
  }
};

const forgetPassword = () => {
  showLoginDialog.value = false;
  showForgetDialog.value = true;
};

const signup = async () => {
  isError.value = false;
  const valid =
    formRef.value && formRef.value.errors
      ? (await formRef.value.validate()).valid
      : true;

  if (valid) {
    const formData = new FormData();
    for (const key in form) {
      if (key === "has_read_policy") {
        formData.set(key, form[key]);
        formData.set("accept_terms", form[key]);
      }
      formData.set(key, form[key]);
    }

    const cartItems =
      JSON.parse(localStorage.getItem("itemsInLocalStorage")) || [];

    cartItems.forEach((product, index) => {
      formData.append(`products[${index}][id]`, product.id);
      formData.append(`products[${index}][type]`, product.type);
      if (product.ref) {
        formData.append(`products[${index}][ref]`, product.ref);
      }
      if (product.promo_code) {
        formData.append(`products[${index}][promo_code]`, product.promo_code);
      }
    });

    await authStore.register(formData).then((resData) => {
      setAlertData(resData);

      if (resData.success) {
        trackingPurchaseJourney("SignUp", resData.data);
        login();
      }
    });
  }
};

const login = async () => {
  isError.value = false;
  const valid =
    formRef.value && formRef.value.errors
      ? (await formRef.value.validate()).valid
      : true;

  if (valid) {
    if (showReadPolicyCheckBox.value && hasReadPolicy.value) {
      form.has_read_policy = hasReadPolicy.value;
    }

    loading.value = true;

    const coursesInLocalStorage = JSON.parse(
      localStorage.getItem("itemsInLocalStorage"),
    );

    await authStore
      .login({ ...form, cartItems: coursesInLocalStorage })
      .then(async (res) => {
        setAlertData({ success: res.success, message: res.message });
        if (res.status_code === 200) {
          trackingPurchaseJourney("SignIn", res.data);

          showLoginDialog.value = false;
          emit("closeModal", {
            value: true,
            showSignup: false,
            checkBundle: true,
          });
          if (props.informMeAboutTheMaterial) {
            authStore.informMeWhenMaterialBeAvailable = true;
          }
        }
        await homeStore.fetchHome();
        loading.value = false;
        if (res.status_code === 200) {
          showLoginDialog.value = false;
          if (props.openThisPath) {
            navigateTo(props.openThisPath);
          }
          localStorage.removeItem("itemsInLocalStorage");
        } else {
          handleLoginError(res);
        }
      });

    loading.value = false;
  }
};


const handleLoginError = (e) => {
  if (e.status_code === 310) {
    notActive.value = true;
  } else if (e.status_code === 406) {
    termsDialogContent.value = e.data;
    showReadTermsDialog.value = true;
    showReadPolicyCheckBox.value = true;
  }
  if (e.status_code !== 310) {
    isError.value = true;
    errorMessage.value = e ? e.message : "حدث خطأ ما ، الرجاء إعادة المحاولة";
  }
};

const setAlertData = (data) => {
  alertData.value = data;
  showAlert.value = true;
};

const setNotificationToken = () => {
  // const { messaging } = $fire;
  if (!isSupported() || isIOSDevice.value) {
    return false;
  } else if (Notification) {
    // Notification.requestPermission((permissions) => {
    //   if (permissions === "granted") {
    //     messaging
    //       .getToken({
    //         vapidKey: process.env.fcmPublicVapIdKeyEnv || $config.fcmPublicVapIdKeyConfig
    //       })
    //       .then((currentToken) => {
    //         form.fcm_token = currentToken;
    //       });
    //   }
    // });
  }
};

const setDeviceId = () => {
  if (localStorage.getItem("deviceId") === null) {
    const generateRandomId = Math.floor(Math.random() * 899999 + 100000);
    localStorage.setItem("deviceId", generateRandomId);
  }
  form.device_id = localStorage.getItem("deviceId");
};

const closeChangeEmailDialog = () => {
  emit("closeModal", { value: true, showSignup: showSignup.value });
  showLoginDialog.value = false;
  setTimeout(() => {
    showChangeEmailForm.value = false;
    notActive.value = false;
  }, 400);
};

const onAcceptTerms = () => {
  hasReadPolicy.value = true;
  showReadTermsDialog.value = false;
};

const onTermsDialogClosed = (status) => {
  showReadTermsDialog.value = false;
  if (status) {
    showLoginDialog.value = false;
  }
};

if ((countries.value && !countries.value.length) || !countries.value) {
  await homeStore
    .fetchCountries({ list: true, select_country: true })
    .then(() => {
      if (!form.country_id && countries.value) {
        form.country_id = countries.value[0].id;
      }
    });
}
</script>

<style lang="scss">
@import "./assets/scss/_login.scss";
</style>
