import { defineStore } from "pinia";
import { useRuntimeConfig } from "#app";
import { useAuthStore } from "~/stores/AuthStore";

export const useCartStore = defineStore("cart", {
  state: () => ({
    cartList: null,
    relatedProducts: null,
    bankAccounts: [],
    bankTransferSource: [],
    orderDetails: {},
    orderDetailsForGTM: {},
    loading: false,
    btnLoading: false,
    production: "https://api.sumaya369.net/v1/",
    testing: "https://testing-api.sumaya369.net/v1/",
    tapOrderDetails: null,
    applePayOrderDetails: null,
    paymentUrl: null,
    cartDetails: null,
  }),

  actions: {
    set(payload) {
      this.cartList = payload;
    },
    add(payload) {
      this.cartList.push(payload);
    },
    getCartItems(payload) {
      this.cartList = payload;
      this.cartList.forEach((item) => {
        item.show_gift_field = false;
      });
    },
    getRelatedProducts(payload) {
      this.relatedProducts = payload;
    },
    updateItemLocally(payload) {
      const index = this.cartList.findIndex((item) => item.id === payload.id);
      if (index !== -1) {
        this.cartList[index] = payload;
      }
    },
    remove(i) {
      this.cartList.splice(i, 1);
    },
    setBankAccounts(payload) {
      this.bankAccounts = payload;
    },
    setTransferSources(payload) {
      this.bankTransferSource = payload;
    },
    setLoading(payload) {
      this.loading = payload;
    },
    setTapOrderDetails(payload) {
      this.tapOrderDetails = payload;
    },
    setApplePayOrderDetails(payload) {
      this.applePayOrderDetails = payload;
    },
    setOrderDetailsForGTM(payload) {
      this.orderDetailsForGTM = payload;
    },
    setPaymentUrl(payload) {
      this.paymentUrl = payload;
    },
    async addItemToCart(payload) {
      this.setLoading(true);
      // try {
      const authStore = useAuthStore();
      const { data, error } = await useFetch(
        `${useRuntimeConfig().public.baseUrl}cart/add-product`,
        {
          method: "POST",
          body: payload,
          headers: authStore.auth.headers,
        },
      );
      this.setLoading(false);
      if (error.value) {
        const { checkStatusCode } = useCheckRequestStatusCode();
        checkStatusCode(error.value.data ? error.value.data.status_code : 401);
        return error.value.data;
      } else {
        await this.getCartDetails();
        return data.value;
      }
      // } catch (err) {
      //   this.setLoading(false);
      //   return err.data;
      // }
    },
    async getCartDetails() {
      const authStore = useAuthStore();

      const { data, error } = await useFetch(
        `${useRuntimeConfig().public.baseUrl}cart`,
        {
          method: "POST",
          headers: authStore.auth.headers,
        },
      );
      this.setLoading(false);
      if (error.value) {
        const { checkStatusCode } = useCheckRequestStatusCode();
        checkStatusCode(error.value.data ? error.value.data.status_code : 401);
        return error.value.data;
      } else if (data.value) {
        this.getCartItems(data.value.data.cart_items);
        this.getRelatedProducts(data.value.related_products);
        this.cartDetails = data.value.data;
        return data.value;
      }
    },
    async getVisitorCartDetails(payload) {
      const authStore = useAuthStore();

      const { checkStatusCode } = useCheckRequestStatusCode();
      this.setLoading(true);
      const formData = new FormData();
      if (payload && payload.length) {
        payload.forEach((product, index) => {
          formData.append(`products[${index}][id]`, product.id);
          formData.append(`products[${index}][type]`, product.type);
          if (product.ref) {
            formData.append(`products[${index}][ref]`, product.ref);
          }
          if (product.promo_code) {
            formData.append(`products[${index}][promo_code]`, product.promo_code);
          }
        });
      }
      // try {
      const { data, error } = await useFetch(
        `${useRuntimeConfig().public.baseUrl}visitor-cart`,
        {
          method: "POST",
          body: formData,
          headers: authStore.auth.headers,
        },
      );
      this.setLoading(false);
      if (error.value) {
        checkStatusCode(error.value.data ? error.value.data.status_code : 401);

        return error.value.data;
      } else {
        if (data.value && data.value.data && data.value.data.cart_items) {
          this.set(data.value.data.cart_items);
        }
        return data.value;
      }
      // } catch (err) {
      //   this.setLoading(false);
      //   return err.data ? err.data.message : 'حدث خطأ';
      // }
    },
    async getCheckoutDetails(payload) {
      const authStore = useAuthStore();
      const { checkStatusCode } = useCheckRequestStatusCode();
      // try {
      this.btnLoading = true;
      const { data, error } = await useFetch(
        `${useRuntimeConfig().public.baseUrl}cart/checkout`,
        {
          method: "POST",
          body: payload,
          headers: authStore.auth.headers,
        },
      );

      this.btnLoading = false;

      if (error.value) {
        checkStatusCode(error.value.data ? error.value.data.status_code : 401);

        return error.value.data;
      } else {
        if (
          data.value &&
          data.value.data.cart_items &&
          data.value.data.cart_items.payment_url
        ) {
          this.setPaymentUrl(data.value.data.cart_items.payment_url);
        }
        this.orderDetails = data.value.data.cart_items;
        return data.value;
      }
      // } catch (err) {
      //   this.setLoading(false);
      //   return err.data ? err.data.message : 'حدث خطأ';
      // }
    },
    async getBankAccounts() {
      try {
        const authStore = useAuthStore();
        const { checkStatusCode } = useCheckRequestStatusCode();
        const { data, error } = await useFetch(
          `${useRuntimeConfig().public.baseUrl}bank-accounts`,
          {
            method: "GET",
            headers: authStore.auth.headers,
          },
        );
        if (error.value) {
          checkStatusCode(
            error.value.data ? error.value.data.status_code : 401,
          );
          return error.value.data;
        }
        this.setBankAccounts(data.value.data.bank_accounts);
        this.setTransferSources(data.value.data.bank_transfer_source);
        return data.value;
      } catch (err) {
        return err.data;
      }
    },
    async postSpotiiDetails(payload) {
      try {
        const authStore = useAuthStore();
        const { checkStatusCode } = useCheckRequestStatusCode();
        const { error } = await useFetch(
          `${useRuntimeConfig().public.baseUrl}${payload.spotii_checkout_url}`,
          {
            method: "POST",
            headers: authStore.auth.headers,
            body: payload.token,
          },
        );
        if (error.value) {
          checkStatusCode(
            error.value.data ? error.value.data.status_code : 401,
          );
          return error.value.data;
        }
      } catch (err) {
        return err.data;
      }
    },
    async removeFromCart(itemId) {
      this.setLoading(true);
      try {
        const authStore = useAuthStore();
        const { checkStatusCode } = useCheckRequestStatusCode();
        const { data, error } = await useFetch(
          `${useRuntimeConfig().public.baseUrl}cart/delete-product/${itemId}`,
          {
            method: "DELETE",
            headers: authStore.auth.headers,
          },
        );
        if (error.value) {
          this.setLoading(false);

          checkStatusCode(
            error.value.data ? error.value.data.status_code : 401,
          );
          return error.value.data;
        }
        await this.getCartDetails();
        return data.value;
      } catch (err) {
        this.setLoading(false);
        return err.data;
      }
    },
    async rechargeCreditCard(payload) {
      try {
        const authStore = useAuthStore();
        const { checkStatusCode } = useCheckRequestStatusCode();
        const { data, error } = await useFetch(
          `${useRuntimeConfig().public.baseUrl}orders/${payload}/recharge`,
          {
            method: "GET",
            headers: authStore.auth.headers,
            body: payload.formData,
          },
        );
        if (error.value) {
          checkStatusCode(
            error.value.data ? error.value.data.status_code : 401,
          );
          return error.value.data;
        }
        this.setPaymentUrl(data.value.data.payment_url);
        return data.value;
      } catch (err) {
        return err.data;
      }
    },
    async rechargeSpotii(payload) {
      try {
        const authStore = useAuthStore();
        const { checkStatusCode } = useCheckRequestStatusCode();
        const { data, error } = await useFetch(
          `${useRuntimeConfig().public.baseUrl}orders/${payload}/spotii/recharge`,
          {
            method: "GET",
            headers: authStore.auth.headers,
            body: payload.formData,
          },
        );
        if (error.value) {
          checkStatusCode(
            error.value.data ? error.value.data.status_code : 401,
          );
          return error.value.data;
        }
        return data.value;
      } catch (err) {
        return err.data;
      }
    },
    async rechargeTabby(payload) {
      try {
        const authStore = useAuthStore();
        const { checkStatusCode } = useCheckRequestStatusCode();
        const { data, error } = await useFetch(
          `${useRuntimeConfig().public.baseUrl}orders/${payload}/tabby/checkout`,
          {
            method: "GET",
            headers: authStore.auth.headers,
          },
        );
        if (error.value) {
          checkStatusCode(
            error.value.data ? error.value.data.status_code : 401,
          );
          return error.value.data;
        }
        return data.value;
      } catch (err) {
        return err.data;
      }
    },
    async rechargeTamara(payload) {
      try {
        const authStore = useAuthStore();
        const { checkStatusCode } = useCheckRequestStatusCode();
        const { data, error } = await useFetch(
          `${useRuntimeConfig().public.baseUrl}orders/${payload}/tamara/checkout`,
          {
            method: "GET",
            headers: authStore.auth.headers,
          },
        );
        if (error.value) {
          checkStatusCode(
            error.value.data ? error.value.data.status_code : 401,
          );
          return error.value.data;
        }
        return data.value;
      } catch (err) {
        return err.data;
      }
    },
    async validateEmails(payload) {
      const authStore = useAuthStore();
      const { checkStatusCode } = useCheckRequestStatusCode();
      try {
        const { data, error } = await useFetch(
          `${useRuntimeConfig().public.baseUrl}cart/validate-emails`,
          {
            method: "POST",
            headers: authStore.auth.headers,
            body: payload,
          },
        );
        if (error.value) {
          checkStatusCode(
            error.value.data ? error.value.data.status_code : 401,
          );
          return error.value.data;
        }
        return data.value;
      } catch (err) {
        return err.data;
      }
    },
    async tabbyCallback(payload) {
      const endpoint =
        process.env.NODE_ENV === "production"
          ? `${this.production}allowed-origins/callback/tabby-callback`
          : `${this.testing}allowed-origins/callback/tabby-callback`;
      const authStore = useAuthStore();
      const { checkStatusCode } = useCheckRequestStatusCode();
      try {
        const { data, error } = await useFetch(
          `${useRuntimeConfig().public.baseUrl}${endpoint}`,
          {
            method: "POST",
            headers: authStore.auth.headers,
            body: payload,
          },
        );
        if (error.value) {
          checkStatusCode(
            error.value.data ? error.value.data.status_code : 401,
          );
          return error.value.data;
        }
        return data.value;
      } catch (err) {
        return err.data;
      }
    },
    async tamaraCallback(payload) {
      const endpoint =
        process.env.NODE_ENV === "production"
          ? `${this.production}allowed-origins/callback/tamara-callback`
          : `${this.testing}allowed-origins/callback/tamara-callback`;
      const authStore = useAuthStore();
      const { checkStatusCode } = useCheckRequestStatusCode();
      try {
        const { data, error } = await useFetch(
          `${useRuntimeConfig().public.baseUrl}${endpoint}`,
          {
            method: "POST",
            headers: authStore.auth.headers,
            body: payload,
          },
        );
        if (error.value) {
          checkStatusCode(
            error.value.data ? error.value.data.status_code : 401,
          );
          return error.value.data;
        }
        return data.value;
      } catch (err) {
        return err.data;
      }
    },
    async spotiiCallback(payload) {
      const authStore = useAuthStore();
      const { checkStatusCode } = useCheckRequestStatusCode();
      try {
        const { data, error } = await useFetch(
          `orders/spotii-callback/${payload}`,
          {
            method: "POST",
            headers: authStore.auth.headers,
          },
        );
        if (error.value) {
          checkStatusCode(
            error.value.data ? error.value.data.status_code : 401,
          );
          return error.value.data;
        }
        return data.value;
      } catch (err) {
        return err.data;
      }
    },
    async getTapOrderDetails(chargeId) {
      const authStore = useAuthStore();
      const { checkStatusCode } = useCheckRequestStatusCode();
      try {
        const { data, error } = await useFetch(
          `${useRuntimeConfig().public.baseUrl}retrieve-tap-status/${chargeId}`,
          {
            method: "GET",
            headers: authStore.auth.headers,
          },
        );
        if (error.value) {
          checkStatusCode(
            error.value.data ? error.value.data.status_code : 401,
          );
          return error.value.data;
        }
        this.setTapOrderDetails(data.value);
        return data.value;
      } catch (err) {
        return err.data;
      }
    },
    async getApplePayOrderDetails(orderId) {
      const authStore = useAuthStore();
      const { checkStatusCode } = useCheckRequestStatusCode();
      try {
        const { data, error } = await useFetch(
          `${useRuntimeConfig().public.baseUrl}check-paytabs-status/${orderId}`,
          {
            method: "GET",
            headers: authStore.auth.headers,
          },
        );
        if (error.value) {
          checkStatusCode(
            error.value.data ? error.value.data.status_code : 401,
          );
          return error.value.data;
        }
        this.setApplePayOrderDetails(data.value);
        return data.value;
      } catch (err) {
        return err.data;
      }
    },
     async getOrderDetailsForGTM(orderId) {
      const authStore = useAuthStore();
      const { checkStatusCode } = useCheckRequestStatusCode();
      try {
        const { data, error } = await useFetch(
          `${useRuntimeConfig().public.baseUrl}orders/${orderId}/tag-manager-details`,
          {
            method: "GET",
            headers: authStore.auth.headers,
          },
        );
        if (error.value) {
          checkStatusCode(
            error.value.data ? error.value.data.status_code : 401,
          );
          return error.value.data;
        }
        else {
          this.setOrderDetailsForGTM(data.value.data);
          return data.value.data;
        }
      } catch (err) {
        return err.data;
      }
    },
    async sendTapCallBack(payload) {
      const authStore = useAuthStore();
      const { checkStatusCode } = useCheckRequestStatusCode();
      try {
        const { error } = await useFetch(
          `${useRuntimeConfig().public.baseUrl}tap-response`,
          {
            method: "POST",
            headers: authStore.auth.headers,
            body: {
              id: payload.id,
              reference: { order: payload.order_reference_id },
              sent_from_frontend: payload.from_frontend,
            },
          },
        );
        if (error.value) {
          checkStatusCode(
            error.value.data ? error.value.data.status_code : 401,
          );
          return error.value.data;
        }
      } catch (err) {
        return err.data;
      }
    },
    async informMeAboutProductsAvailabilities(payload) {

      const authStore = useAuthStore();
      const { checkStatusCode } = useCheckRequestStatusCode();
      try {
        const { data, error } = await useFetch(
          `${useRuntimeConfig().public.baseUrl}products-availabilities`,
          {
            method: "POST",
            headers: authStore.auth.headers,
            body: payload,
          },
        );
        if (error.value) {
          checkStatusCode(
            error.value.data ? error.value.data.status_code : 401,
          );
          return error.value.data;
        }
        return data.value;
      } catch (err) {
        return err.data;
      }
    },
  },
});
