<template>
  <div class="menu">
    <Alert
      :alert-visible="showAlert"
      :alert-data="alertData"
      @close="showAlert = false"
    />
    <v-menu rounded="lg" offset-y transition="slide-y-transition">
      <template v-slot:activator="{ props }">
        <v-btn
          size="small"
          width="40"
          height="40"
          icon
          v-bind="props"
          class="px-0 py-0"
        >
          <img src="~/assets/images/header/menu.svg" class="cursor-pointer" />
        </v-btn>
      </template>
      <v-list>
        <div class="account-btns">
          <div v-if="!isLogin">
            <v-btn class="login" @click="showLogin = true"> دخول </v-btn>
            <v-btn class="signup" @click="sinup()"> تسجيل حساب </v-btn>
          </div>
          <div v-if="isLogin" class="profile-menu">
            <span class="user-btn">
              <v-avatar class="profile-img">
                <!-- <img src="/images/settings/profile-img.png" alt="profile-img"> -->
                <img :src="user.avatar" alt="profile img" />
              </v-avatar>
              <span>{{
                user.first_name
                  ? `${user.first_name}  ${user.last_name}`
                  : user.full_name
              }}</span>
            </span>
            <ul>
              <li v-for="page in settings" :key="page.title">
                <span v-if="page.islogout" @click="logout" class="logout-btn">
                  <img
                    :src="`/images/settings/${page.icon}.svg`"
                    class="icon"
                  />
                  <span>{{ page.title }}</span>
                </span>

                <nuxt-link
                  v-else-if="
                    page.icon === 'marketing' &&
                    user.role === 'affiliate_marketer'
                  "
                  :to="page.link"
                >
                  <img
                    :src="`/images/settings/${page.icon}.svg`"
                    class="icon"
                  />
                  <span>{{ page.title }}</span>
                </nuxt-link>

                <nuxt-link
                  v-if="
                    !page.islogout &&
                    page.icon !== 'marketing' &&
                    page.icon !== 'group-application'
                  "
                  :to="page.link"
                >
                  <img
                    :src="`/images/settings/${page.icon}.svg`"
                    class="icon"
                  />
                  <span>{{ page.title }}</span>
                </nuxt-link>

                <nuxt-link
                  v-if="page.icon === 'group-application'"
                  :to="hasDesires"
                >
                  <img
                    :src="`/images/settings/${page.icon}.svg`"
                    class="icon"
                  />
                  <span>{{ page.title }}</span>
                </nuxt-link>
              </li>
            </ul>
          </div>
        </div>
        <ul class="pages-btns">
          <li v-for="page in pagesList" :key="page.title" class="menu">
            <a v-if="page.isNotNuxtLink" :href="page.link" target="_blank' ">
              {{ page.title }}
            </a>
            <a
              v-else-if="
                page.link && page.static && page.title !== 'المواد المخفضة'
              "
              :href="page.link"
              target=""
            >
              {{ page.title }}
            </a>
            <nuxt-link
              v-else-if="
                page.link && page.static && page.title === 'المواد المخفضة'
              "
              :to="{
                path: `${page.link}`,
                query: { all_reduced_courses: true },
              }"
            >
              {{ page.title }}
            </nuxt-link>
            <a
              v-else-if="page.nuxtLink && inHomePage"
              :href="page.link"
              target=""
            >
              {{ page.title }}
            </a>
            <a
              v-else-if="isLogin && page.title === 'قاموس الوعي'"
              :href="page.link"
              target=""
            >
              {{ page.title }}
            </a>
            <a
              v-else-if="isLogin && page.title === 'استشارات توجيهية'"
              :href="page.link"
              target=""
            >
              {{ page.title }}
            </a>
            <span
              v-else-if="!isLogin && page.title === 'قاموس الوعي'"
              @click="showLogin = true"
              class="awareness-dictionary"
            >
              {{ page.title }}
            </span>
          </li>
          <li v-for="page in dynamicPagesList" :key="page.title" class="menu">
            <nuxt-link v-if="!page.logged_in_only" :to="`/pages/${page.key}`">
              {{ page.title }}
            </nuxt-link>
            <span
              v-else-if="page.logged_in_only && !isLogin"
              @click="showLogin = true"
              class="page-link"
            >
              {{ page.title }}
            </span>
            <nuxt-link
              v-else-if="page.logged_in_only && isLogin"
              :to="`/pages/${page.key}`"
            >
              {{ page.title }}
            </nuxt-link>
          </li>
        </ul>
      </v-list>
    </v-menu>
    <AuthVisitorLoginModal
      :dialog-visible="showLogin"
      @closeModal="isLoginDialogClosed"
    />
  </div>
</template>

<script setup>
import { ref, computed, onMounted } from "vue";

import { useRoute, useRouter } from "vue-router";
import LoginModal from "~/components/auth/LoginModal.vue";
import Alert from "~/components/shared/Alert.vue";
import { useAuthStore } from "~/stores/AuthStore";
import { useSettingsStore } from "~/stores/SettingsStore";
import { useHomeStore } from "~/stores/HomeStore";

const homeStore = useHomeStore();
const settingsStore = useSettingsStore();
const authStore = useAuthStore();

// Initialize store and router

const route = useRoute();
const router = useRouter();

const showLogin = ref(false);
const alertData = ref({});
const showAlert = ref(false);
const dynamicPagesList = ref([]);
const hasDesires = ref("/group-application-request");

const pagesList = ref([
  { title: "عن د.سمية", link: "/pages/about", static: true },
  { title: "كافة المواد", link: "/materials", static: true },
  { title: "المواد المخفضة", link: "/materials", static: true },
  { title: "الإصدارات", link: "/#publications", nuxtLink: "/publications" },
  { title: "المقالات", link: "/#articles", nuxtLink: "/articles" },
  { title: "أحداث الشهر", link: "/events", static: true },
  { title: "قاموس الوعي", link: "/pages/awareness-dictionary" },
  { title: "تطبيق لاكس", link: "https://Lax.elyak.com", isNotNuxtLink: true },
  {
    title: "استشارات توجيهية",
    link: "https://book.sumaya369.net/",
    isNotNuxtLink: false,
  },
  { title: "الأسئلة الشائعة", link: "/FAQ", static: true },
]);

const settings = ref([
  {
    title: "إشتراكاتي",
    icon: "subscriptions",
    link: "/settings/subscriptions",
  },
  { title: "طلباتي", link: "/settings/orders", icon: "orders" },
  { title: "إدارة الحساب", link: "/settings/profile", icon: "profile" },
  { title: "التسويق", link: "/settings/marketing", icon: "marketing" },
  { title: "المفضلة", link: "/settings/favorites", icon: "favorites" },
  {
    title: "فئات الخصم ",
    link: "/settings/groupApplication",
    icon: "group-application",
  },
  { title: "تسجيل الخروج", link: "", icon: "signout", islogout: true },
]);

const inHomePage = computed(() => route.path === "/");
const isLogin = computed(() => authStore.auth.isLoggedIn);
const user = computed(() => authStore.auth.user || {});
const header = computed(() => homeStore.header);
const profile = computed(() => settingsStore.profile.user);
const hasGroupApplicationRequest = computed(() => {
  if (profile.value) {
    return profile.value.gar_information;
  }
  return "unknown";
});

watch(
  () => hasGroupApplicationRequest.value,
  (newVal) => {
    if (newVal !== "unknown") {
      hasDesires.value = "/settings/GroupApplication";
    } else hasDesires.value = "/group-application-request";
  },
  { deep: true },
);

onMounted(() => {
  if (header.value.header) {
    header.value.header.pages.forEach((element) => {
      dynamicPagesList.value.push(element);
    });
  }
});

const isLoginDialogClosed = (payload) => {
  if (payload.value) {
    showLogin.value = false;
    if (payload.showSignup) {
      sinup();
    }
  }
};

const sinup = () => {
  navigateTo("/auth/signup");
};

const logout = async () => {
  const formData = new FormData();
  formData.append("device_id", localStorage.getItem("deviceId"));
  const payload = {
    formData,
    device_id: localStorage.getItem("deviceId"),
  };
  await authStore.logout(payload).then((resData) => {
    setAlertData(resData);
    navigateTo("/");
  });
};

const checkAuthUser = (page) => {
  if (isLogin.value) {
    return true;
  } else if (page.nuxtLink === "pages/awareness-dictionary") {
    return false;
  }
  return true;
};

const setAlertData = (data) => {
  alertData.value = data;
  showAlert.value = true;
};
if (authStore.auth.user) {
  settingsStore.showProfile();
}
</script>

<style lang="scss" scoped>
.v-list {
  width: 284px !important;
  top: 0 !important;
  right: 15px !important;
  z-index: 1001 !important;
  .page-link {
    font-weight: bold;
    cursor: pointer;
  }
  .pages-btns {
    padding: 15px 28px;
    list-style: none;
    background-color: #fff;
    margin: 0;
    font-weight: bold;
    font-size: 16px;
    li {
      margin: 15px 0;
      .page-link,
      a {
        text-decoration: none;
        color: #000;
        &:hover,
        &.nuxt-link-activ {
          color: #87599d;
        }
      }
    }
  }
}
.account-btns {
  display: none;
}
// @media (max-width: 1200px) {
//   // .menu-btn {
//   //   margin-top: -10px;
//   // }
// }
@media (max-width: 768px) {
  .account-btns {
    display: block;
    & > div {
      text-align: center;
      padding: 12px;
      background-color: #fff;
      border-bottom: 3px solid #f7f7f7;
      button {
        border-radius: 21px;
        background-color: #87599d !important;
        letter-spacing: normal;
        text-align: center;
        color: #fff;
        font-size: 15px;
        font-weight: bold;
      }
      .login {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
      }
      .signup {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        margin-right: -2px;
      }
    }
    .user-btn {
      border-bottom: 3px solid #f7f7f7;
      font-size: 18px;
      font-weight: bold;
      padding: 12px;
      display: flex;
      align-items: center;
      text-align: right;
      .profile-img img {
        width: 37px !important;
        height: 37px !important;
      }
    }
    .profile-menu {
      background-color: #f0f0f0;
      padding: 0;
      ul {
        padding: 12px 20px;
        list-style: none;
        margin: 0;
        font-weight: bold;
        font-size: 15px;
        font-weight: normal;
        li {
          margin: 15px 0;
          img {
            margin-left: 10px;
          }
          a,
          .logout-btn {
            display: flex;
            align-items: center;
            text-decoration: none;
            color: #000;
            cursor: pointer;
            &:hover,
            &.nuxt-link-active {
              color: #87599d;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 600px) {
  .v-list {
    max-height: 506px;
  }
}
</style>
